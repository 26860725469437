import {
	ForwardedRef,
	forwardRef,
	PropsWithChildren,
	ReactElement,
} from 'react';

import { classes } from '@/uiPrimitives/base/classes.helpers';

import {
	scrollInline,
	scrollInlineMeasure,
	scrollInlineContent,
	scrollInlineWrapper,
	scrollInlineMasks,
} from './ScrollInline.css';
import { focusWrapper } from '@/uiPrimitives/base/focus.css';

const ScrollInline_ = (
	{
		children,
		className,
		buttonBeforeSlot,
		buttonAfterSlot,
		measureBeforeSlot,
		measureAfterSlot,
		mask,
	}: PropsWithChildren<{
		className?: string;
		buttonBeforeSlot?: ReactElement | false;
		buttonAfterSlot?: ReactElement | false;
		measureBeforeSlot?: ReactElement | false;
		measureAfterSlot?: ReactElement | false;
		mask?: keyof typeof scrollInlineMasks;
	}>,
	ref: ForwardedRef<HTMLDivElement>
) => {
	return (
		<div className={classes(className, scrollInlineWrapper)}>
			{buttonBeforeSlot}
			<div className={classes(focusWrapper)}>
				<div
					ref={ref}
					className={classes(
						scrollInline,
						mask != null && scrollInlineMasks[mask]
					)}
					tabIndex={0}
				>
					<div className={classes(scrollInlineMeasure)}>
						{measureBeforeSlot}
						<div className={classes(scrollInlineContent)}>{children}</div>
						{measureAfterSlot}
					</div>
				</div>
			</div>
			{buttonAfterSlot}
		</div>
	);
};
export const ScrollInline = forwardRef(ScrollInline_);
