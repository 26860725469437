'use client';

import { produce } from 'immer';
import { ReactElement, useId, useReducer } from 'react';
import { Collapse } from '@/uiComposites/interactive/Collapse';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { textBlock, textStyle } from '@/uiPrimitives/typography/text';
import { box } from '@/uiPrimitives/layout/box';
import { icon } from '@/uiPrimitives/media/icon';
import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { stack } from '@/uiPrimitives/layout/stack';
import { occupy } from '@/uiPrimitives/layout/occupy';
import {
	accordionContentPadding,
	delayIconTransitionHover,
} from './Accordion.css';
import { Translation } from '@/globals/translate/translations';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { pile } from '@/uiPrimitives/layout/pile';

const openedItemsReducer = produce(
	(
		openedItemsDraft: Set<number>,
		action:
			| { type: 'toggle'; index: number }
			| { type: 'openAll'; itemsCount: number }
			| { type: 'closeAll' }
	) => {
		switch (action.type) {
			case 'toggle':
				if (openedItemsDraft.has(action.index)) {
					openedItemsDraft.delete(action.index);
					return;
				}

				openedItemsDraft.add(action.index);
				return;

			case 'openAll':
				for (let index = 0; index < action.itemsCount; index += 1) {
					openedItemsDraft.add(index);
				}
				return;

			case 'closeAll':
				openedItemsDraft.clear();
				return;
		}
	}
);

export type AccordionItem = {
	summaryElement: ReactElement;
	contentElement: ReactElement;
};

export const AccordionClient = ({
	accordionItems,
	openAllLabel,
	closeAllLabel,
	className,
	Heading = 'h3',
}: {
	accordionItems: Array<AccordionItem>;
	openAllLabel?: Translation<'Open all'>;
	closeAllLabel?: Translation<'Close all'>;
	className?: string;
	Heading?: 'h2' | 'h3' | 'h4';
}) => {
	const [openedItems, dispatchOpenedItems] = useReducer(
		openedItemsReducer,
		new Set<number>()
	);

	const baseID = useId();

	if (accordionItems.length === 0) return null;

	const areAllItemsOpen = openedItems.size === accordionItems.length;

	return (
		<div
			className={classes(stack({}), className)}
			{...addTestSelector('uiAccordion')}
		>
			{accordionItems.length > 1 && openAllLabel && closeAllLabel && (
				<div
					className={classes(
						placement({ alignSelf: 'end' }),
						occupy({ blockSize: '0px', alignItems: 'end' })
					)}
				>
					<div
						className={classes(
							box({
								paddingBlockEnd: '1 | h3',
							})
						)}
					>
						<button
							className={classes(
								textStyle({ capSize: '-2 | smaller', color: 'positiveBlue' }),
								shelf({ gap: '-1 | small', alignItems: 'baseline' })
							)}
							onClick={() => {
								if (areAllItemsOpen) {
									dispatchOpenedItems({ type: 'closeAll' });
								} else {
									dispatchOpenedItems({
										type: 'openAll',
										itemsCount: accordionItems.length,
									});
								}
							}}
							{...addTestSelector('uiToggleAllAccordionsButton')}
						>
							<div className={classes(textBlock({}))}>
								{areAllItemsOpen ? closeAllLabel : openAllLabel}
							</div>
							<div className={classes(occupy({ blockSize: '1ex' }))}>
								<div
									className={classes(
										box({
											borderRadius: 'circle',
											borderStyle: 'solid',
											borderWidth: '1px',
											aspectRatio: '1 / 1',
											blockSize: '2 | h2',
										}),
										pile({ alignItems: 'center', justifyItems: 'center' })
									)}
								>
									<i
										className={classes(
											icon({
												icon: chevronIcon,
												blockSize: '1ex',
												adjustIcon: 'rotate90deg',
												flip: areAllItemsOpen
													? 'horizontal:hover'
													: 'horizontalNone:hover',
											})
										)}
									/>
								</div>
							</div>
						</button>
					</div>
				</div>
			)}
			<div
				className={classes(
					box({
						borderBlockStartStyle: 'solid',
						borderWidth: '1px',
						borderColor: 'positiveMuted',
					})
				)}
			>
				{accordionItems.map(({ summaryElement, contentElement }, index) => {
					const isOpened = openedItems.has(index);
					const id = `${baseID}-${index}`;

					return (
						<div
							key={index}
							className={classes(
								box({
									borderBlockEndStyle: 'solid',
									borderWidth: '1px',
									borderColor: 'positiveMuted',
								})
							)}
						>
							<Heading className={classes(stack({}))}>
								<button
									aria-controls={id}
									aria-expanded={isOpened}
									onClick={() => {
										dispatchOpenedItems({
											type: 'toggle',
											index,
										});
									}}
									className={classes(
										shelf({
											gap: '-1 | small',
											gridTemplateColumns: 'minmax(0, 1fr) auto',
											alignItems: 'baseline',
										}),
										box({
											paddingBlock: '1 | h3',
										}),
										textStyle({
											color: 'positiveBlue',
											fontWeight: isOpened ? 'bold' : undefined,
										})
									)}
									{...addTestSelector(`uiAccordionItem-${index}`)}
								>
									<div className={classes(textBlock({}))}>{summaryElement}</div>
									<i
										className={classes(
											delayIconTransitionHover,
											icon({
												icon: chevronIcon,
												adjustIcon: 'rotate90deg',
												flip: isOpened
													? 'horizontal:hover'
													: 'horizontalNone:hover',
												blockSize: '1ex',
											})
										)}
									/>
								</button>
							</Heading>
							<Collapse id={id} isOpened={isOpened}>
								<div
									className={classes(accordionContentPadding, textBlock({}))}
									{...addTestSelector(`uiAccordionContent-${index}`)}
								>
									{contentElement}
								</div>
							</Collapse>
						</div>
					);
				})}
			</div>
		</div>
	);
};
