import { pushEvent } from '../pushEvent';

export const pushYouTubePlayingEvent = () => {
	pushEvent({
		events: {
			category: 'interaction.videoPlay',
			action: 'play',
		},
	});
};
