'use client';

import { classes } from '@/uiPrimitives/base/classes.helpers';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { collapseAnimation } from './Collapse.css';

export const useCollapse = (isOpened: boolean) => {
	const [animationSettled, setAnimationSettled] = useState(true);
	const prefersReduceMotionRef = useRef<boolean>(false);
	const settledIsOpenedRef = useRef<boolean>(isOpened);
	useEffect(() => {
		if (settledIsOpenedRef.current === isOpened) return;
		settledIsOpenedRef.current = isOpened;

		if (!prefersReduceMotionRef.current) {
			setAnimationSettled(false);
		}
	}, [isOpened]);

	useEffect(() => {
		const prefersReduceMotionMatcher = matchMedia(
			'(prefers-reduced-motion: reduce)'
		);

		const abortController = new AbortController();

		prefersReduceMotionRef.current = prefersReduceMotionMatcher.matches;

		prefersReduceMotionMatcher.addEventListener(
			'change',
			(event) => {
				prefersReduceMotionRef.current = event.matches;

				if (prefersReduceMotionRef.current) {
					setAnimationSettled(true);
				}
			},
			{ signal: abortController.signal }
		);

		return () => {
			abortController.abort();
		};
	}, []);

	return {
		outerProps: {
			className: classes(collapseAnimation[isOpened ? 'opened' : 'closed']),
			onTransitionEnd: () => {
				setAnimationSettled(true);
			},
		},
		innerProps: {
			'data-fully-opened': isOpened && animationSettled,
			// Not yet supported by react,
			//  this is a work around to get it in the HTML anyway.
			inert: !isOpened ? ('' as never) : undefined,
		},
	};
};

export const Collapse = ({
	isOpened,
	children,
	id,
}: PropsWithChildren<{ isOpened: boolean; id: string }>) => {
	const { innerProps, outerProps } = useCollapse(isOpened);
	return (
		<div id={id} {...outerProps}>
			<div {...innerProps}>{children}</div>
		</div>
	);
};
