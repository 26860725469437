'use client';

import type { Translation } from '@/globals/translate/translations';
import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { position } from '@/uiPrimitives/layout/position';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { icon } from '@/uiPrimitives/media/icon';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { ScrollInline } from './ScrollInline';
import { measureAfter, measureBefore } from './ScrollInline.css';
import { box } from '@/uiPrimitives/layout/box';
import { pile } from '@/uiPrimitives/layout/pile';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';

const useShowScrollButtons = () => {
	const scrollAreaRef = useRef<HTMLDivElement>(null);
	const measureBeforeRef = useRef<HTMLElement>(null);
	const measureAfterRef = useRef<HTMLElement>(null);
	const [showBeforeButton, setShowBeforeButton] = useState(false);
	const [showAfterButton, setShowAfterButton] = useState(false);

	useEffect(() => {
		const scrollAreaElement = scrollAreaRef.current;
		const beforeElement = measureBeforeRef.current;
		const afterElement = measureAfterRef.current;

		if (
			scrollAreaElement == null ||
			beforeElement == null ||
			afterElement == null
		)
			return;

		const intersectionObserver = new IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					if (entry.target === beforeElement) {
						setShowBeforeButton(!entry.isIntersecting);
					}

					if (entry.target === afterElement) {
						setShowAfterButton(!entry.isIntersecting);
					}
				}
			},
			{
				root: scrollAreaRef.current,
			}
		);

		intersectionObserver.observe(beforeElement);
		intersectionObserver.observe(afterElement);

		return () => {
			intersectionObserver.disconnect();
		};
	}, []);

	return {
		scrollAreaRef,
		measureBeforeRef,
		measureAfterRef,
		showBeforeButton,
		showAfterButton,
	};
};

export const ScrollInlineWithButtonsClient = ({
	children,
	className,
	beforeButtonLabel,
	afterButtonLabel,
}: PropsWithChildren<{
	className?: string;
	beforeButtonLabel: Translation<'Scroll left'>;
	afterButtonLabel: Translation<'Scroll right'>;
}>) => {
	const {
		measureBeforeRef,
		measureAfterRef,
		scrollAreaRef,
		showBeforeButton,
		showAfterButton,
	} = useShowScrollButtons();

	return (
		<ScrollInline
			ref={scrollAreaRef}
			className={classes(className, zIndex({ isolation: 'isolate' }))}
			measureBeforeSlot={
				<i className={classes(measureBefore)} ref={measureBeforeRef} />
			}
			measureAfterSlot={
				<i className={classes(measureAfter)} ref={measureAfterRef} />
			}
			mask={
				showBeforeButton && showAfterButton
					? 'both'
					: showBeforeButton
					? 'before'
					: showAfterButton
					? 'after'
					: undefined
			}
			buttonBeforeSlot={
				<button
					type="button"
					{...{
						// Not yet supported by react,
						//  this is a work around to get it in the HTML anyway.
						inert: showBeforeButton ? undefined : ('' as never),
					}}
					onClick={() => {
						const scrollAreaElement = scrollAreaRef.current;
						if (scrollAreaElement == null) return;

						const scrollDelta = (2 / 3) * scrollAreaElement.clientWidth;

						// scrollBy has janky behaviour on iOS Safari
						scrollAreaElement.scrollTo({
							left: scrollAreaElement.scrollLeft - scrollDelta,
							behavior: 'smooth',
						});
					}}
					className={classes(
						zIndex({ zIndex: '1' }),
						position({
							position: 'absolute',
							insetInlineStart: '-1px',
							insetBlock: '0',
						}),
						box({
							inlineSize: indentSize,
							opacity: showBeforeButton ? '1' : '0',
						}),
						pile({
							justifyItems: 'stretch',
							alignItems: 'stretch',
						})
					)}
				>
					<span
						className={classes(
							pile({
								justifyItems: 'start',
								alignItems: 'center',
							})
						)}
					>
						<i
							aria-label={beforeButtonLabel}
							className={classes(
								icon({
									icon: chevronIcon,
									adjustIcon: 'flipHorizontal',
									fill: 'positiveBlue',
								})
							)}
						/>
					</span>
				</button>
			}
			buttonAfterSlot={
				<button
					type="button"
					{...{
						// Not yet supported by react,
						//  this is a work around to get it in the HTML anyway.
						inert: showAfterButton ? undefined : ('' as never),
					}}
					onClick={() => {
						const scrollAreaElement = scrollAreaRef.current;
						if (scrollAreaElement == null) return;

						const scrollDelta = (2 / 3) * scrollAreaElement.clientWidth;

						// scrollBy has janky behaviour on iOS Safari
						scrollAreaElement.scrollTo({
							left: scrollAreaElement.scrollLeft + scrollDelta,
							behavior: 'smooth',
						});
					}}
					className={classes(
						zIndex({ zIndex: '1' }),
						position({
							position: 'absolute',
							insetInlineEnd: '-1px',
							insetBlock: '0',
						}),
						box({
							inlineSize: indentSize,
							opacity: showAfterButton ? '1' : '0',
						}),
						pile({
							justifyItems: 'stretch',
							alignItems: 'stretch',
						})
					)}
				>
					<span
						className={classes(
							pile({
								justifyItems: 'end',
								alignItems: 'center',
							})
						)}
					>
						<i
							aria-label={afterButtonLabel}
							className={classes(
								icon({ icon: chevronIcon, fill: 'positiveBlue' })
							)}
						/>
					</span>
				</button>
			}
		>
			{children}
		</ScrollInline>
	);
};
